@font-face {
  font-family: "Suisse";
  font-style: normal;
  font-weight: 100;
  src: url("./assets/font/EOT/SuisseIntl-Ultralight-WebTrial.eot"); /* IE9 Compat Modes */
  src: local("Suisse"), local("Suisse"),
    url("./assets/font/EOT/SuisseIntl-Ultralight-WebTrial.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/font/Woff2/SuisseIntl-Ultralight-WebTrial.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/font/Woff/SuisseIntl-Ultralight-WebTrial.woff") format("woff"),
    /* Modern Browsers */ url("./assets/font/TTF/SuisseIntl-Ultralight-WebTrial.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./assets/font/SVG/SuisseIntl-Ultralight-WebTrial.svg#Suisse") format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "Suisse";
  font-style: normal;
  font-weight: 200;
  src: url("./assets/font/EOT/SuisseIntl-Thin-WebTrial.eot"); /* IE9 Compat Modes */
  src: local("Suisse"), local("Suisse"),
    url("./assets/font/EOT/SuisseIntl-Thin-WebTrial.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/font/Woff2/SuisseIntl-Thin-WebTrial.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/font/Woff/SuisseIntl-Thin-WebTrial.woff") format("woff"),
    /* Modern Browsers */ url("./assets/font/TTF/SuisseIntl-Thin-WebTrial.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./assets/font/SVG/SuisseIntl-Thin-WebTrial.svg#Suisse") format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "Suisse";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/font/EOT/SuisseIntl-Light-WebTrial.eot"); /* IE9 Compat Modes */
  src: local("Suisse"), local("Suisse"),
    url("./assets/font/EOT/SuisseIntl-Light-WebTrial.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/font/Woff2/SuisseIntl-Light-WebTrial.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/font/Woff/SuisseIntl-Light-WebTrial.woff") format("woff"),
    /* Modern Browsers */ url("./assets/font/TTF/SuisseIntl-Light-WebTrial.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./assets/font/SVG/SuisseIntl-Light-WebTrial.svg#Suisse") format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "Suisse";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/font/EOT/SuisseIntl-Regular-WebTrial.eot"); /* IE9 Compat Modes */
  src: local("Suisse"), local("Suisse"),
    url("./assets/font/EOT/SuisseIntl-Regular-WebTrial.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/font/Woff2/SuisseIntl-Regular-WebTrial.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/font/Woff/SuisseIntl-Regular-WebTrial.woff") format("woff"),
    /* Modern Browsers */ url("./assets/font/TTF/SuisseIntl-Regular-WebTrial.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./assets/font/SVG/SuisseIntl-Regular-WebTrial.svg#Suisse") format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "Suisse";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/font/EOT/SuisseIntl-Medium-WebTrial.eot"); /* IE9 Compat Modes */
  src: local("Suisse"), local("Suisse"),
    url("./assets/font/EOT/SuisseIntl-Medium-WebTrial.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/font/Woff2/SuisseIntl-Medium-WebTrial.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/font/Woff/SuisseIntl-Medium-WebTrial.woff") format("woff"),
    /* Modern Browsers */ url("./assets/font/TTF/SuisseIntl-Medium-WebTrial.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./assets/font/SVG/SuisseIntl-Medium-WebTrial.svg#Suisse") format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "Suisse";
  font-style: normal;
  font-weight: bold;
  src: url("./assets/font/EOT/SuisseIntl-Bold-WebTrial.eot"); /* IE9 Compat Modes */
  src: local("Suisse"), local("Suisse"),
    url("./assets/font/EOT/SuisseIntl-Bold-WebTrial.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/font/Woff2/SuisseIntl-Bold-WebTrial.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/font/Woff/SuisseIntl-Bold-WebTrial.woff") format("woff"),
    /* Modern Browsers */ url("./assets/font/TTF/SuisseIntl-Bold-WebTrial.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./assets/font/SVG/SuisseIntl-Bold-WebTrial.svg#Suisse") format("svg"); /* Legacy iOS */
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Suisse", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
  max-width: 100%;
}

.DraftEditor-root {
  flex: 1;
  overflow: auto;
  padding-top: 20px;
}
.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
  height: 100% !important;
}
.public-DraftEditor-content blockquote {
  margin: 0;
  padding-inline-start: 0.5em;
  border-left: 3px solid #bbb;
}
.public-DraftEditor-content ul {
  margin: 0;
}
.public-DraftEditor-content ol {
  margin: 0;
}
.public-DraftEditor-content h1 {
  font-size: 24px;
  margin: 0;
}
.public-DraftEditor-content h2 {
  font-size: 20px;
  margin: 0;
}
.public-DraftEditor-content h3 {
  font-size: 18px;
  margin: 0;
}

/* Tippy.js global styles.  */
.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}
[data-tippy-root] {
  /* max-width: calc(100vw - 10px); */
  max-width: 280px;
  text-transform: lowercase;
  font-size: 1rem;
  line-height: 1.25;
}
.tippy-box {
  position: relative;
  background-color: #121d32;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  outline: 0;
  transition-property: transform, visibility, opacity;
}
.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}
.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  bottom: -4px;
  left: 0;
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: center top;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  top: -4px;
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: center bottom;
}
.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}
.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  right: -4px;
  transform-origin: center left;
}
.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}
.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  left: -4px;
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: center right;
}
.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}
.tippy-arrow {
  width: 16px;
  height: 16px;
  color: #121d32;
}
.tippy-arrow:before {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid;
}
.tippy-content {
  position: relative;
  z-index: 1;
}
